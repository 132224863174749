import { accountSummaries } from "/@/account-management/store/account"
import { accountStreamer, twApiClient } from "/@lib/tastyworks-rest"
import {
  Balance,
  BALANCE_DESER,
  JsonHelper,
  StreamerMessageType,
} from "@tastyworks/tastyworks-api"

let summaries = new Map()

accountSummaries.subscribe((updated) => {
  summaries = updated
  refreshBalances()
})

function refreshBalances() {
  // Async updates so UI doesn't have to wait, numbers will gradually fill in
  for (const accountSummary of summaries.values()) {
    twApiClient.balanceService
      .show(accountSummary.accountNumber)
      .then((balance) => {
        if (!balance.data) {
          return
        }

        accountSummary.balance.set(balance.data)
      })
  }
}

function balanceObserver(
  messageType: StreamerMessageType,
  dataHelper: JsonHelper,
  timestamp: number,
  accountNumber: string
) {
  const balance = new Balance()
  BALANCE_DESER.update(balance, dataHelper)

  const accountSummary = summaries.get(accountNumber)

  if (!accountSummary) return

  accountSummary.balance.set(balance)
}

export function initializeStreamingBalances() {
  accountStreamer.addMessageObserver(
    StreamerMessageType.AccountBalance,
    balanceObserver
  )
  accountStreamer.addReconnectObserver(() => refreshBalances())

  refreshBalances()
}
